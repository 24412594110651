html {
  background-color: #ffffff;
  color: #151515;
  font-family: "Noto Sans JP", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
  font-size: 16px;
}

body {
  margin: 0;
  height: 100%;
}

h1 {
  font-size: 1.4rem;
  border-bottom: 2px solid #98c8be;
  margin: 10px 0 20px 0;
  padding: 5px 0;
}

h2 {
  font-size: 1.2rem;
}

input:focus, select:focus {
  outline: none;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  width: 85%;
  max-width: 70rem;
  margin: auto;
}

.header {
  width: 100%;
  box-sizing: border-box;

  margin-bottom: 20px;
  padding: 5px 0;

  box-shadow: 0 3px 10px #00000040;

  .headerBox {
    width: 90%;
    max-width: 75rem;
    margin: 5px auto;
  
    display: flex;
    align-items: center;
  
    justify-content: left;

    .logoBox {
      display: flex;
      align-items: center;
      justify-content: left;
      margin-right: 10px;
    }
  }
}

.headLogo {
  height: 40px;
}

.headLogoImg {
  height: 40px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-left: 15px;
}

.searchBox, .headerSearchBox {
  display: flex;
  align-items: center;
  width: 100%;
  flex-grow: 1;

  > * {
    display: inline-block;
    box-sizing: border-box;
    min-width: 0;
    &:last-child {
      border-right: 1px solid #a2908b;
    }
  }

  .textInput {
    width: 0;
    flex-grow: 1;
    background-color: #faf9f9;
    border: 1px solid #a2908b;
    border-right-width: 0;

    &::placeholder {
      font-style: italic;
    }
  }
  
  .searchButton {
    display: flex;
    background-color: #014f41;
    color: white;
    cursor: pointer;
    border: none;
    border-radius: 0 3px 3px 0;
    width: 2em;
    justify-content: center;
    align-items: center;
  }
}

.headerSearchBox {
  justify-content: end;
  max-width: 20rem;
  margin-left: 15px;

  > * {
    height: 1.8rem;
  }
  .textInput {
    font-size: 1rem;
    
    padding-left: 0.3rem;
  }
}

.searchBox {
  justify-content: center;
  margin: 1rem 0;

  > * {
    height: 2.3rem;
  }

  .textInput {
    padding-left: 0.5rem;
    font-size: 1rem;
  }

  .selectContainer {
    position: relative;
    max-width: fit-content;
    flex-grow: 1;
    width: 2rem;
    flex-shrink: 1;

    .tagSelect, .ruleSelect {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid #a2908b;
      border-right-width: 0;
      font-size: 0.9rem;
      background-color: #f0eae6;
      padding: 0 30px 0 10px;
      height: 100%;
      width: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      right: 10px;
      top: 15px;
      width: 7px;
      height: 7px;
      border-top: 2px solid #808080;
      border-left: 2px solid #808080;
      pointer-events: none;
      transform: translateY(-50%) rotate(-135deg);
    }
  }

  .searchButton {
    font-size: 1.1rem;
  }
}

.footer {
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  box-sizing: border-box;

  margin-top: 50px;
  padding: 30px 0;

  box-shadow: 0 3px 10px #00000040 inset;

  text-align: center;

  .credit {
    margin-top: 10px;
  }
}

.indexLinkBox {
  display: flex;
  flex-direction: row;
  margin-left: auto;

  .indexLink {
    border-left: 1px solid #a0a0a0;
    font-size: 0.9rem;
    padding: 0 8px;
    text-wrap: nowrap;

    a {  
      color: inherit;
      text-decoration: none;
    }
    &:last-child {
      border-right: 1px solid #a0a0a0;
    }
  }
}

.topBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;

  .topLogoBox {
    width: 80%;
    max-width: 350px;
    margin: 25px 0 5px 0;
    user-select: none;
    pointer-events: none;

    .topLogo {
      width: 100%;
    }
  }
  .topTitle {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  .indexLinkBox {
    margin-left: 0;
  }
}

.charaList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));

  .charaLink {
    width: 60px;
    height: 70px;
  
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    margin: 7px;
    border: 1px solid #a0a0a0;
    border-radius: 3px;

    &:hover {
      background-color: #edf8f6;
    }

    .chara {
      font-size: 25px;
    }
    .charaPron {
      font-size: 0.8rem;
      margin-top: 5px;
    }
  }
  > a {
    color: initial;
    text-decoration: none;
  }
}

.pageWrapper {
  display: flex;
  flex-flow: row;
}

.info {
  display: flex;
  flex-flow: column;
  width: 25%;
  margin-right: 30px;

  .moveLink {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    border-bottom: 1px solid #a0a0a0;

    .nextButton {
      border-left: 1px solid #a0a0a0;
    }

    .prevButton, .nextButton {
      flex-grow: 1;

      > * {
        display: inline-block;
        text-decoration: none;
        font-size: 1.2rem;
        color: #028069;
        width: 100%;
        height: 2rem;
        text-align: center;
        user-select: none;

        &:hover {
          background-color: #edf8f6;
        }
      }

      &.disabled > * {
        background-color: #e0e0e0;
        color: #707070;
      }
    }
  }

  > .charaFrame {
    width: 100%;
    height: 150px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    > .mainChara {
      font-size: 100px;
    }
  }

  .kanji {
    display: inline-block;
    width: 100%;
    font-size: 24px;
    text-align: center;
  }
}

.phun, .searchBox .textInput::placeholder, .headerSearchBox .textInput::placeholder {
  font-family: "Phun-Sans", "PhunWrite", "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
  font-weight: normal;
}

.serif {
  font-family: "Noto Serif JP", serif;
}

.dataTable {
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 0.5rem;

  * {
    border: 1px solid #a0a0a0;
    padding: 5px;
  }

  th {
    background-color: #f5f5f5;
    width: 30%;
  }
}

.langs {
  flex-grow: 1;

  > .langData {
    > h2 {
      border-left: 5px solid #014f41;
      padding: 2px 0 2px 0.5em;
      margin: 30px 0 15px 0;
      
      font-size: 1.3rem;
    }
    &:first-child > h2 {
      margin-top: 15px;
    }

    .dataRow {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #a0a0a0;
      padding: 0;
      font-size: 1rem;

      .dataLabel {
        font-weight: bold;
        background-color: #dcf2ee;
        width: 5rem;
        padding: 5px 0 5px 7px;
        margin-right: 10px;
      }
    }

    > .dataEntry > p {
      margin: 0;
    }

    .langLinks {
      width: 100%;
      padding: 0.5rem 0.3rem;
      padding-bottom: 0;

      span {
        margin: 0 0.3rem;
      }
    }
  }
}

.charaStructure {
  .treeNode, .nodeRow {
    &::after {
      content: "";
      position: absolute;
      height: 10px;
      top: 0;
      left: 50%;
      border-right: 1px #c0c0c0 solid;
    }
  }

  .treeNode {
    position: relative;
    flex-grow: 1;
    text-align: center;
    margin-top: 10px;
    line-height: 1;
    padding-top: 10px;

    .specialDesc {
      height: calc(1.1rem + 7px);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #404040;
    }
  
    .nodeChara {
      box-sizing: border-box;
      border: 1px solid #707070;
      padding: 3px 5px;
      border-radius: 3px;
      font-size: 1.1rem;
      display: inline-flex;
      flex-direction: row;
      align-items: end;
    
      &:hover {
        background-color: #edf8f6;
      }

      .nodeKanji {
        font-size: 0.8rem;
        margin-left: 5px;
        color: #404040;
      }
    }
  
    &.modified .nodeChara {
      border-style: dashed;
    }
  
    a {
      text-decoration: none;
      color: inherit;
      height: 100%;
    }
  
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      border-top: 1px #c0c0c0 solid;
    }
  }

  > .treeNode {
    margin-top: 0;
    padding-top: 0;

    &::after, &::before {
      display: none;
    }
  }

  .nodeRow {
    display: flex;
    flex-direction: row;
    position: relative;

    > .treeNode {
      &:first-child::before {
        width: 50%;
        right: 0;
        left: auto;
      }
      &:last-child::before {
        width: 50%;
        left: 0;
        right: auto;
      }
    }
  }
}

.charaFontBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  column-gap: 20px;

  .charaFont {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .fontName {
      display: inline-flex;
      height: 60px;
      width: 60px;
      border: 1px solid #a0a0a0;
      justify-content: center;
      align-items: center;
      user-select: none;
      font-size: 50px;

      &.pwrite {
        font-family: "PhunWrite";
      }
      &.psans {
        font-family: "Phun-Sans";
      }
      &.pdot {
        font-family: "PhunDot";
      }
    }
    .fontNameLabel {
      font-size: 0.9rem;
      margin-top: 5px;
    }
  }
}

.phun404 {
  font-size: 24px;
}

@media screen and (max-width: 750px) {
  .main {
    width: 85%;
  }
  .pageWrapper, .headerBox {
    flex-flow: column;
  }
  .header .headerBox {
    width: 90%;
    align-items: center;
  }
  .logoBox {
    margin: 5px 0 15px 0 !important;
  }
  .indexLinkBox {
    margin-bottom: 10px;
    margin-left: 0;
  }
  .searchBox {
    flex-wrap: wrap;

    .textInput {
      width: 100%;
      border-right-width: 1px;
      border-bottom-width: 0;
    }
    .selectContainer {
      max-width: 100%;

      .tagSelect, .ruleSelect {
        font-size: 0.85rem;
        padding: 0 20px 0 5px;
      }
    }
    .searchButton {
      border-top-right-radius: 0;
    }
  }
  .headerSearchBox {
    justify-content: center;
    max-width: 35rem;
    margin-left: 0;
  }
  .info {
    width: 100%;
    margin: 0;
  }
  .langs > .langData:first-child > h2 {
    margin-top: 30px;
  }
  .nodeChara {
    flex-direction: column !important;
    align-items: center !important;

    .nodeKanji {
      margin-top: 5px !important;
      margin-left: 0 !important;
    }
  }
}

@font-face {
  font-family: "PhunWrite";
  src: url(https://kaeru2193.github.io/Phun-Resources/font/PhunWrite-Regular-latest.woff) format("woff");
}

@font-face {
  font-family: "Phun-Sans";
  src: url(https://kaeru2193.github.io/Phun-Resources/font/Phun-Sans/Phun-Sans-Rounded-Regular.woff) format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Phun-Sans";
  src: url(https://kaeru2193.github.io/Phun-Resources/font/Phun-Sans/Phun-Sans-Rounded-Bold.woff) format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "PhunDot";
  src: url(https://kaeru2193.github.io/Phun-Resources/font/PhunDot-latest.woff) format("woff");
}